.Wrapper {
  flex: 1;
  overflow: hidden;
}

.BrowserWindow {
  flex: 1 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  border: 3px solid #f1f1f1;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  box-shadow: 2px 2px 6px rgba(0,0,0,.25);
}

.BrowserRow {
  padding: 5px 10px;
  background: #f1f1f1;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  flex: 0 0 auto;
}

.BrowserColumnLeft,
.BrowserColumnMiddle,
.BrowserColumnRight {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: auto;
}

.BrowserColumnLeft {
  flex: 0 0 60px;
  justify-content: space-between;
  padding-right: 15px;
}

.BrowserColumnMiddle {
  flex: 1;
}

.BrowserColumnRight {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  padding-left: 15px;
}

.BrowserButton {
}

.BrowserMenuRight {
  display: inline-block;
  widows: 30px;
}

.BrowserBar {
  width: 17px;
  height: 3px;
  background-color: #aaa;
  margin: 3px 0;
  display: block;
}

.BrowserInput {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: none;
  background-color: white;
  color: #666;
  padding: 5px;
  display: flex;
  align-items: center;
}

.BrowserContent {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  overflow: auto;
}

.TabBar {
  flex: 0 0 auto;
  background-color: #f7f7f7;
  border-top: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: var(--comfortable-font-size-sans-small);
}

.Tab,
.TabActive {
  height: 2rem;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.TabActive {
  border-bottom-color: #06F;
}

.Tab:hover,
.TabActive:hover {
  background-color: rgba(0,0,0,.05);
}

.Frame {
  width: 100%;
  flex: 0 0 150px;
  border: 0;
}

.DevTools {
  width: 100%;
  flex: 1 0 200px;
  height: 100%;
  overflow: auto;
}

@media screen and (max-width: 600px) {
  .DevTools {
    min-height: 400px;
  }
}