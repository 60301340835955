.Button,
.ButtonDisabled {
  border: 0;
  background: none;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  outline: none;
}

.Button:focus,
.Button:hover {
  background-color: rgba(0,0,0,.05);
}

.ButtonDisabled {
  opacity: 0.5;
}

.Icon {
  height: 24px;
  width: 24px;
}